html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

* {
    box-sizing: border-box;
}

.container {
    min-height: 100vh;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

main {
    padding: 1rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}



footer {
    width: 100%;
    height: 100px;
    border-top: 1px solid #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
}

footer img {
    margin-left: 1.5rem;
}

footer a {
    display: flex;
    justify-content: center;
    align-items: center;
}

a {
    color: #0070f3;
    text-decoration: none;
}

a:hover {
    color: #FFFFFF;
    background-color: #0070f3;
}

.title a {
    color: #0070f3;
    text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
    text-decoration: underline;
}

.title {
    margin: 0;
    line-height: 1.15;
    font-size: 4rem;
}

.title,
.description {
    text-align: center;
}

.description {
    line-height: 1.5;
    font-size: 1.5rem;
}

code {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 300px;
    max-width: 1600px;
}

.card {
    margin: 1rem;
    flex-basis: 45%;
    min-width: '100vw';
    padding: 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    background-color: white;
}

.card-half {
    margin: 1rem;
    flex-basis: 22%;
    padding: 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    background-color: white;
}

.card-double {
    margin: 1rem;
    flex-basis: 100%;
    padding: 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    background-color: white;
}

.card:hover,
.card:focus,
.card:active {
    /*color: #0070f3; */
    border-color: #e6f1fe;
    color: #000;
    background-color: #e6f1fe;
    
}

.card h3 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

.card p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
}

.logo {
    height: 2em;
}

.error {
    color: red;
    margin:1svh;
    font-size: small;
}

.content {
    margin: '32px'
}

@media (max-width: 600px) {
    .grid {
    width: 100%;
    flex-direction: column;
    }
}